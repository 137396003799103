import {useState, useEffect} from 'react'
import '../css/card_container.css'

import restApiHost from '..';


function CardContainer({activeCategory}) {

    
    const [countryName, setCountryName] = useState(null);
    const [casinoCards, setCasinoCards] = useState(null);
    const [betsCards, setBetsCards] = useState(null);
    

    useEffect(() => {

        async function fetchCards () {
            const response = await fetch(restApiHost + '/api/get_games/' + countryName + '/');
            const data = await response.json();
            const cards = data.data;
            setBetsCards(cards.filter(game => game.category === 'Ставки на спорт'));
            setCasinoCards(cards.filter(game => game.category === 'Казино'));
        };

        async function fetchCountryName() {
            const response = await fetch('https://zaymi-online24.store/geo/get_country_name/');
            const data = await response.json();
            const country = data.country_name.toLowerCase();
            setCountryName(country);
        }
        
        if (countryName === null && casinoCards === null && betsCards === null) {
            fetchCountryName();
        }

        if (casinoCards === null && countryName !== null && betsCards === null){
            fetchCards();
        }


    }, [casinoCards, betsCards, countryName])


    const redirectToGame = (link) => {
        window.open(link, '_self');
    };


    const renderCards = () => {
        const elements = [];
        let cards = null;
        if (activeCategory === 'Казино') {
            cards = casinoCards;
        } else {
            cards = betsCards
        }
            
        for (let i = 0; i < cards.length; i += 3) {
            
            elements.push(
                <div key={`big-card-${cards[i].id}`} onClick={() => redirectToGame(cards[i].link)} className="big-card" style={{backgroundImage: `url(${cards[i].image})`, 
                boxShadow: `0px 0px 6px 2px ${cards[i].color}, ` +
                '3px 3px 2.6px 0px rgba(0, 0, 0, 0.25), ' +
                `inset 0px 0px 13px 0px ${cards[i].color}`
                }}>
                    <div className='play-button-big-card'> 
                        {cards[i].category === 'Казино' ? (<div className='play-button-text-big-card'>ИГРАТЬ</div>) :
                            (<div className='play-button-text-big-card'>СТАВИТЬ</div>)
                        }
                    </div>
                </div>
            );

            if (i + 1 < cards.length || i + 2 < cards.length) {
                elements.push(
                    <div key={`little-cards-${i}`} className="little-cards-container">
                        {i + 1 < cards.length && (
                            <div className="little-card" onClick={() => redirectToGame(cards[i + 1].link)} style={{backgroundImage: `url(${cards[i + 1].image})`,
                            boxShadow: `0px 0px 6px 2px ${cards[i + 1].color}, ` +
                            '3px 3px 2.6px 0px rgba(0, 0, 0, 0.25), ' +
                            `inset 0px 0px 13px 0px ${cards[i + 1].color}`
                            }}>
                                <div className='play-button-big-card' onClick={() => redirectToGame(cards[i + 1].link)}> 
                                    {cards[i + 1].category === 'Казино' ? (<div className='play-button-text-big-card'>ИГРАТЬ</div>) :
                                        (<div className='play-button-text-big-card'>СТАВИТЬ</div>)
                                    }
                                </div>
                            </div>
                        )}
                        {i + 2 < cards.length && (
                            <div className="little-card" onClick={() => redirectToGame(cards[i + 2].link)} style={{backgroundImage: `url(${cards[i + 2].image})`,
                            boxShadow: `0px 0px 6px 2px ${cards[i + 2].color}, ` +
                            '3px 3px 2.6px 0px rgba(0, 0, 0, 0.25), ' +
                            `inset 0px 0px 13px 0px ${cards[i + 2].color}`
                            }}>
                            <div className='play-button-big-card' onClick={() => redirectToGame(cards[i + 2].link)}> 
                                    {cards[i + 2].category === 'Казино' ? (<div className='play-button-text-big-card'>ИГРАТЬ</div>) :
                                                (<div className='play-button-text-big-card'>СТАВИТЬ</div>)
                                    }
                            </div>
                            </div>
                        )}
                    </div>
                );
            }
        }

        return elements;
    };

    return <div className="card-container">{betsCards && (renderCards())}<div className='break-line'></div></div>;    
    
};

export default CardContainer;