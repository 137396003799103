import { useState, useEffect } from "react";
import UpperMenu from "./components/UpperMenu";

import CardContainer from "./components/CardContainer";
import restApiHost from ".";

function App() {
  const [category, setCategory] = useState('Казино')
  const [isBackgroundSeted, setBackgroundSetted] = useState(false);


  useEffect(()  => {
    async function fetchBackgroundImage() {
      const response = await fetch(restApiHost + '/api/get_background_image/');
      const data = await response.json();
      
      document.body.style.backgroundImage = `url(${data.image})`;
      setBackgroundSetted(true);
    };

    if (!isBackgroundSeted) {
      fetchBackgroundImage();
    }
  }, [isBackgroundSeted]);

  return (
    <div className="main">
      {isBackgroundSeted ? (<div>
      <UpperMenu categoryToogleButton={setCategory} activeCategory={category}/>
      <CardContainer activeCategory={category}/>
    </div>) : (<div></div>)}

    </div>
  );
}

export default App;
