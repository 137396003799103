import '../css/upper_menu.css'


function UpperMenu({categoryToogleButton, activeCategory}) {



    return (
        <div className="upper-menu">
            {activeCategory === 'Казино' ?(
                <div className="toogle-button-active" onClick={() => categoryToogleButton('Казино')}>
                        <div className="toogle-button_text">Казино</div>
                    </div>
                ) : (
                <div className="toogle-button" onClick={() => categoryToogleButton('Казино')}>
                    <div className="toogle-button_text">Казино</div>
                </div>
            )}
            
            {activeCategory === 'Ставки на спорт' ?(
                <div className="toogle-button-active" onClick={() => categoryToogleButton('Ставки на спорт')}>
                        <div className="toogle-button_text">Ставки на спорт</div>
                    </div>
                ) : (
                <div className="toogle-button" onClick={() => categoryToogleButton('Ставки на спорт')}>
                    <div className="toogle-button_text">Ставки на спорт</div>
                </div>
            )}
        </div>
    )

};

export default UpperMenu;